// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-covid-19-policy-js": () => import("./../../../src/pages/covid-19-policy.js" /* webpackChunkName: "component---src-pages-covid-19-policy-js" */),
  "component---src-pages-eat-and-drink-js": () => import("./../../../src/pages/eat-and-drink.js" /* webpackChunkName: "component---src-pages-eat-and-drink-js" */),
  "component---src-pages-golf-js": () => import("./../../../src/pages/golf.js" /* webpackChunkName: "component---src-pages-golf-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meetings-js": () => import("./../../../src/pages/meetings.js" /* webpackChunkName: "component---src-pages-meetings-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-property-js": () => import("./../../../src/pages/property.js" /* webpackChunkName: "component---src-pages-property-js" */),
  "component---src-pages-romance-js": () => import("./../../../src/pages/romance.js" /* webpackChunkName: "component---src-pages-romance-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-specials-js": () => import("./../../../src/pages/specials.js" /* webpackChunkName: "component---src-pages-specials-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

