module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://cms.mtirvine.com/graphql","auth":{"htaccess":{"username":"andel@agyei.designn","password":"madman"}},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#08424D","theme_color":"#08424D","display":"minimal-ui","icon":"src/images/icon-logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dbaaf8549668c105fc1a794d9c4edf75"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
